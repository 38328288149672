var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.finisherGiftTitle"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"pa-5"},[_c('v-img',{staticClass:"mx-auto mb-2",attrs:{"src":require('@/assets/explore/gift.png'),"width":"200"}}),_c('div',{staticClass:"text-center mb-4 mx-auto",staticStyle:{"max-width":"250px"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Your Finisher Gift is on the way to")])]),_c('v-card',{staticClass:"ma-auto pa-3 d-flex flex-column align-center text-h5",attrs:{"max-width":"300","flat":"","rounded":"0"}},[_c('div',[_vm._v("EDISON")]),_c('div',[_vm._v("1C")]),_c('div',[_vm._v("SJKC HAMOCHI")]),_c('div',[_vm._v("KUANTAN")])]),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"height":"30","x-small":"","text":"","color":"blue","large":""},on:{"click":() => {
                    _vm.$router.push({
                      name: 'PageEventGiftForm',
                    });
                  }}},[_vm._v(" "+_vm._s(_vm.$t("atring.change"))+" ")])],1),_c('div',{staticClass:"text-center mt-6"},[_c('v-btn',{staticClass:"text-h6",attrs:{"height":"34","color":"yellow","large":"","width":"200"},on:{"click":() => {
                    _vm.$router.push({
                      name: 'PageEventLevels',
                    });
                  }}},[_vm._v(" "+_vm._s(_vm.$t("action.ok"))+" ")])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }