<template>
  <!-- <transition name="fade" mode="out-in"> -->
    <div class="screen-wrap">
      <v-app class="hmc-app">
        <v-main class="main-body">
          <v-container class="app-body">
            <div class="pe-2 ps-3 app-title text-h5">
              {{$t("string.finisherGiftTitle")}}
              <v-spacer></v-spacer>
              <CloseBtn size="40" :callback="exit" />
            </div>
            <div class="pa-5">
              <v-img
                :src="require('@/assets/explore/gift.png')"
                width="200"
                class="mx-auto mb-2"
              ></v-img>
              <div class="text-center mb-4 mx-auto" style="max-width: 250px">
                <div class="text-h5">Your Finisher Gift is on the way to</div>
              </div>
              <v-card
                max-width="300"
                flat
                class="ma-auto pa-3 d-flex flex-column align-center text-h5"
                rounded="0"
              >
                <div>EDISON</div>
                <div>1C</div>
                <div>SJKC HAMOCHI</div>
                <div>KUANTAN</div>
              </v-card>
              <div class="text-right">
                <v-btn
                  height="30"
                  x-small
                  text
                  color="blue"
                  large
                  @click="
                    () => {
                      $router.push({
                        name: 'PageEventGiftForm',
                      });
                    }
                  "
                >
                {{$t("atring.change")}}
                </v-btn>
              </div>

              <div class="text-center mt-6">
                <v-btn
                  class="text-h6"
                  height="34"
                  color="yellow"
                  large
                  width="200"
                  @click="
                    () => {
                      $router.push({
                        name: 'PageEventLevels',
                      });
                    }
                  "
                >
                {{$t("action.ok")}}
                </v-btn>
              </div>
            </div>
          </v-container>
        </v-main>
      </v-app>
    </div>
  <!-- </transition> -->
</template>
    
<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageEventLevels",
      });
    },
  },
};
</script>

    <style scoped>



.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>