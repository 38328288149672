<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="FinisherApi.isError" :api="FinisherApi" />
          <ErrorDialog
            v-if="error"
            :error="error"
            :message="errorMessage"
            @exitError="extiError"
          />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.finisherGiftTitle") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5">
            <v-img
              :src="require('@/assets/explore/gift_1.png')"
              width="300"
              class="mx-auto"
              style="margin-top: -70px; margin-bottom: -30px"
            ></v-img>
            <div class="text-center mb-4 mx-auto" style="max-width: 250px">
              <div class="text-h6">
                {{ $t("string.finisherGiftMsg4") }}
              </div>
            </div>
            <v-text-field
              v-model="name"
              label="Name"
              solo
              flat
              hide-details
              class="my-2"
            ></v-text-field>
            <v-text-field
              v-model="classroom"
              label="Class"
              solo
              flat
              hide-details
              class="my-2"
            ></v-text-field>
            <v-text-field
              v-model="school"
              label="School"
              solo
              flat
              hide-details
              class="my-2"
            ></v-text-field>
            <v-text-field
              v-model="town"
              label="Town"
              solo
              flat
              hide-details
              class="my-2"
            ></v-text-field>

            <div class="text-center mt-6">
              <v-btn
                class="text-h6"
                height="34"
                color="yellow"
                large
                width="200"
                @click="okAction()"
              >
                {{ $t("action.ok") }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    name: "",
    classroom: "",
    school: "",
    town: "",
    FinisherApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    FinisherDataApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    error: false,
    errorMessage: null,
  }),
  created() {
    this.FinisherApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/finisher/load";

    this.FinisherApi.callbackReset = () => {
      this.FinisherApi.isLoading = true;
      this.FinisherApi.isError = false;
    };

    this.FinisherApi.callbackError = (e) => {
      this.FinisherApi.isLoading = false;
      this.FinisherApi.isError = true;
      this.FinisherApi.error = e;
    };
    this.FinisherApi.callbackSuccess = (resp) => {
      this.FinisherApi.isLoading = false;
      if (resp.Finisher) {
        if (this.$route.query.action == "add") {
          this.$router.push({
            name: "PageEventGiftSent",
          });
        }
        this.name = resp.Finisher.name;
        this.classroom = resp.Finisher.classroomName;
        this.school = resp.Finisher.institutionName;
        this.town = resp.Finisher.town;
      } else {
        this.loadFinisherData();
      }
    };
    this.FinisherApi.params = {
      eventCode: this.$route.params.code,
    };
  },
  mounted() {
    if (this.series.isCompleted) {
      this.name = this.auth.User.name;
      this.classroom = this.auth.Classroom ? this.auth.Classroom.name : "";
      this.school = this.auth.Institution ? this.auth.Institution.name : "";
      this.town = this.auth.Institution ? this.auth.Institution.town : "";
      this.$api.fetch(this.FinisherApi);
    } else {
      this.exit();
    }
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageEventLevels",
      });
    },
    okAction() {
      if (
        this.name.trim() == "" ||
        this.classroom.trim() == "" ||
        this.school.trim() == "" ||
        this.town.trim() == ""
      ) {
        this.error = true;
        this.errorMessage = "Fill all feilds";
      } else {
        if (this.$route.query.action == "add") {
          this.saveFinisher();
        }

        if (this.$route.query.action == "update") {
          this.updateFinisher();
        }
      }
    },
    loadFinisherData() {
      this.FinisherDataApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/finisher/load";

      this.FinisherDataApi.callbackReset = () => {
        this.FinisherDataApi.isLoading = true;
        this.FinisherDataApi.isError = false;
      };

      this.FinisherDataApi.callbackError = (e) => {
        this.FinisherDataApi.isLoading = false;
        this.FinisherDataApi.isError = true;
        this.FinisherDataApi.error = e;
      };
      this.FinisherDataApi.callbackSuccess = (resp) => {
        this.FinisherDataApi.isLoading = false;
        if (resp) {
          this.name = resp.name;
          this.classroom = resp.classroomName;
          this.school = resp.institutionName;
          this.town = resp.town;
        }
      };
      this.$api.fetch(this.FinisherDataApi);
    },
    saveFinisher() {
      this.FinisherApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/finisher/save";

      this.FinisherApi.callbackReset = () => {
        this.FinisherApi.isLoading = true;
        this.FinisherApi.isError = false;
      };

      this.FinisherApi.callbackError = (e) => {
        this.FinisherApi.isLoading = false;
        this.FinisherApi.isError = true;
        this.FinisherApi.error = e;
      };
      this.FinisherApi.callbackSuccess = () => {
        this.FinisherApi.isLoading = false;
        this.saveFinisherData();
      };
      this.FinisherApi.params = {
        name: this.name,
        classroomName: this.classroom,
        institutionName: this.school,
        town: this.town,
        eventCode: this.$route.params.code,
        institutionId: this.auth.Institution ? this.auth.Institution.id : null,
        classroomId: this.auth.Classroom ? this.auth.Classroom.id : null,
      };
      this.$api.fetch(this.FinisherApi);
    },
    saveFinisherData() {
      this.FinisherDataApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/finisher/save";

      this.FinisherDataApi.callbackReset = () => {
        this.FinisherDataApi.isLoading = true;
        this.FinisherDataApi.isError = false;
      };

      this.FinisherDataApi.callbackError = (e) => {
        this.FinisherDataApi.isLoading = false;
        this.FinisherDataApi.isError = true;
        this.FinisherDataApi.error = e;
      };
      this.FinisherDataApi.callbackSuccess = () => {
        this.FinisherDataApi.isLoading = false;
        this.$router.push({
          name: "PageEventGiftSent",
        });
      };
      this.FinisherDataApi.params = {
        unverifiedClassroomName: this.classroom,
        unverifiedInstitutionName: this.school,
        unverifiedTownName: this.town,
      };
      this.$api.fetch(this.FinisherDataApi);
    },
    extiError() {
      this.error = false;
    },
    updateFinisher() {
      this.FinisherApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/finisher/edit";

      this.FinisherApi.callbackReset = () => {
        this.FinisherApi.isLoading = true;
        this.FinisherApi.isError = false;
      };

      this.FinisherApi.callbackError = (e) => {
        this.FinisherApi.isLoading = false;
        this.FinisherApi.isError = true;
        this.FinisherApi.error = e;
      };
      this.FinisherApi.callbackSuccess = () => {
        this.FinisherApi.isLoading = false;
        this.saveFinisherData();
      };
      this.FinisherApi.params = {
        name: this.name,
        classroomName: this.classroom,
        institutionName: this.school,
        town: this.town,
        eventCode: this.$route.params.code,
      };
      this.$api.fetch(this.FinisherApi);
    },
  },
};
</script>

    <style scoped>
.list-panel {
  margin-top: 20px;
  padding: 5px 0;
}

.list-card {
  padding: 0 10px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>