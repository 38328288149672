var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[(_vm.FinisherApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.FinisherApi}}):_vm._e(),_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.finisherGiftTitle"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),(_vm.finisher)?_c('div',{staticClass:"pa-5"},[(_vm.finisher.status == 'pending')?_c('v-img',{staticClass:"mx-auto",staticStyle:{"margin-top":"-70px","margin-bottom":"-30px"},attrs:{"src":require('@/assets/explore/gift_2.png'),"width":"300"}}):_c('v-img',{staticClass:"mx-auto",staticStyle:{"margin-top":"-50px","margin-bottom":"-30px"},attrs:{"src":require('@/assets/explore/gift_3.png'),"width":"300"}}),_c('div',{staticClass:"text-center mb-4 mx-auto"},[_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("string.finisherGiftMsg1"))+" ")]),(_vm.finisher.status == 'pending')?_c('div',{staticClass:"text-h5 mt-5"},[_vm._v(" "+_vm._s(_vm.$t("string.finisherGiftMsg2"))+" ")]):_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.finisherGiftMsg3"))+" ")])]),_c('v-card',{staticClass:"ma-auto py-2 px-5 d-flex flex-column align-left text-h6 letter",attrs:{"max-width":"300","flat":"","rounded":"0"}},[_c('div',[_vm._v(_vm._s(_vm.finisher.name))]),_c('div',[_vm._v(_vm._s(_vm.finisher.classroomName))]),_c('div',[_vm._v(_vm._s(_vm.finisher.institutionName))]),_c('div',[_vm._v(_vm._s(_vm.finisher.town))])]),_c('div',[(
                _vm.auth.Group.type != 'institution' &&
                _vm.finisher.status == 'pending' &&
                !_vm.$moment().isAfter(_vm.series.timestampEnd)
              )?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"height":"30","x-small":"","text":"","color":"blue","large":""},on:{"click":() => {
                    _vm.$router.push({
                      name: 'PageEventGiftForm',
                      query: { action: 'update' },
                    });
                  }}},[_vm._v(" "+_vm._s(_vm.$t("string.change"))+" ")])],1):_vm._e(),_c('div',{staticClass:"text-center mt-6"},[_c('v-btn',{staticClass:"text-h6",attrs:{"height":"34","color":"yellow","large":"","width":"300"},on:{"click":() => {
                    _vm.$router.push({
                      name: 'PageEventLevels',
                    });
                  }}},[_vm._v(" "+_vm._s(_vm.$t("action.ok"))+" ")])],1)])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }